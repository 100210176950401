import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import { Script } from "gatsby"
import { connect, useSelector } from "react-redux";
import { actionTypes } from '../store/InvestorQualification/type';
import { moduleTypes } from '../store/type';
import Dialog from "../components/Dialog";
import Loader from "../components/Loader";
import "../styles/pages/investor-qualification.scss";
import AccountsEditLeft from "../components/AccountsEdit/AccountsEditLeft";
import IndividualContactInfoContent from "../components/InvestorQualification/IndividualContactInfoContent";
import IndividualQualificationItemOneContent from "../components/InvestorQualification/QualificationItem/IndividualQualificationItemOneContent";
import IndividualQualificationItemTwoContent from "../components/InvestorQualification/QualificationItem/IndividualQualificationItemTwoContent";
import IndividualQuestionContent from "../components/InvestorQualification/IndividualQuestionContent";
import NewIssuesCertificationContent from "../components/InvestorQualification/NewIssuesCertificationContent";
import SpinningProhibitionCertificationContent from "../components/InvestorQualification/SpinningProhibitionCertificationContent";
import BusinessQualificationItemOneContent from "../components/InvestorQualification/QualificationItem/BusinessQualificationItemOneContent";
import BusinessQualificationItemTwoContent from "../components/InvestorQualification/QualificationItem/BusinessQualificationItemTwoContent";
import TrustQualificationItemOneContent from "../components/InvestorQualification/QualificationItem/TrustQualificationItemOneContent";
import TrustQualificationItemTwoContent from "../components/InvestorQualification/QualificationItem/TrustQualificationItemTwoContent";
import BusinessQuestionContent from "../components/InvestorQualification/BusinessQuestionContent";
import IndividualJointContactInfoContent from "../components/InvestorQualification/IndividualJointContactInfoContent";
import TrustContactInfoContent from "../components/InvestorQualification/TrustContactInfoContent";
import EntityContactInfoContent from "../components/InvestorQualification/EntityContactInfoContent";
import RIAContactInfoContent from "../components/InvestorQualification/RIAContactInfoContent";
import { ACCOUNT_TYPE } from "../Constants";

const SELECT_PAGE_TYPES = [ 
  {name: 'Contact Information', code: 'contact_information'}, 
  {name: 'Investor Qualifications', code: 'investor_qualifications'}, 
  {name: 'Investor Questions', code: 'investor_questions'}, 
  {name: 'New Issues Certification', code: 'new_issues_certification'}, 
  {name: 'Spinning Prohibition Certification', code: 'spinning_prohibition_certification'}, 
];

const RIA_SELECT_PAGE_TYPES = [ 
  {name: 'Contact Information', code: 'contact_information'}, 
];

const InvestorQualification = ({ editingStep, updateEditingStep, loading }) => {

  const [currentStep, setCurrentStep] = useState("");

  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  useEffect(() => {
    if (!currentStep && newAccount) {
      onStepChanged('contact_information');
    }
  }, [newAccount]);

  const onStepChanged = (step) => {
    setCurrentStep(step);
    if (!newAccount) {
      updateEditingStep("");
      return;
    }

    if (step === "contact_information") {
      switch(newAccount.type) {
        case ACCOUNT_TYPE.RIA:
          updateEditingStep("investor-type-ria");
          break;
        case ACCOUNT_TYPE.BUSINESS:
          updateEditingStep("investor-type-business");
          break;
        case ACCOUNT_TYPE.TRUST:
          updateEditingStep("investor-type-trust");
          break;
        case ACCOUNT_TYPE.INDIVIDUAL_JOINT:
          updateEditingStep("investor-type-joint");
          break;
        default:
          updateEditingStep("investor-type-individual");
          break;
      }

    } else if (step === "investor_qualifications") {

      switch(newAccount.type) {
        case ACCOUNT_TYPE.BUSINESS:
          updateEditingStep("qualification-business-one");
          break;
        case ACCOUNT_TYPE.TRUST:
          updateEditingStep("qualification-trust-one");
          break;
        default:
          updateEditingStep("qualification-individual-one");
          break;
      }

    } else if (step === "investor_questions") {
      switch(newAccount.type) {
        case ACCOUNT_TYPE.BUSINESS:
        case ACCOUNT_TYPE.TRUST:
          updateEditingStep("business-question");
          break;
        default:
          updateEditingStep("individual-question");
          break;
      }
    } else if (step === "new_issues_certification") {
      updateEditingStep("new-issues-certification");

    } else if (step === "spinning_prohibition_certification") {
      updateEditingStep("spinning-prohibition-certification");
    }
  }

  if (loading) return <Loader />
  return (
    <>
      <Script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=e5d637d3-794f-4bf2-a312-c029f226b993" />
      <div className="investor-qualification">
        <div className="qualification-item-page contactBg">
          <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
            {/* Left Component */}
            <AccountsEditLeft
              handlerStepChanged={(step) => onStepChanged(step)}
              selectPageTypes = {(newAccount && newAccount.type === ACCOUNT_TYPE.RIA) ? RIA_SELECT_PAGE_TYPES :  SELECT_PAGE_TYPES}
              currentStep={currentStep}
            />

            {/* Right Component */}
            {(() => {
              switch (editingStep) {
                case "qualification-individual-one":
                  return (
                    <IndividualQualificationItemOneContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "qualification-individual-two":
                  return (
                    <IndividualQualificationItemTwoContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "qualification-business-one":
                  return (
                    <BusinessQualificationItemOneContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "qualification-business-two":
                  return (
                    <BusinessQualificationItemTwoContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "qualification-trust-one":
                  return (
                    <TrustQualificationItemOneContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "qualification-trust-two":
                  return (
                    <TrustQualificationItemTwoContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "individual-question":
                  return (
                    <IndividualQuestionContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "business-question":
                  return (
                    <BusinessQuestionContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "new-issues-certification":
                  return (
                    <NewIssuesCertificationContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "spinning-prohibition-certification":
                  return (
                    <SpinningProhibitionCertificationContent
                      isAccountEdit={true}
                      handleOptionClick={(e, qual) => updateEditingStep(e, qual)}
                    />
                  );
                case "investor-type-individual":
                  return (
                    <IndividualContactInfoContent
                      isAccountEdit={true}
                      handleOptionClick={(e) => updateEditingStep(e)}
                    />
                  );
                case "investor-type-joint":
                  return (
                    <IndividualJointContactInfoContent
                      isAccountEdit={true}
                      handleOptionClick={(e) => updateEditingStep(e)}
                    />
                  );
                case "investor-type-sd-ira":
                  return (
                    <IndividualContactInfoContent
                      isAccountEdit={true}
                      handleOptionClick={(e) => updateEditingStep(e)}
                    />
                  );
                case "investor-type-trust":
                  return (
                    <TrustContactInfoContent
                      isAccountEdit={true}
                      handleOptionClick={(e) => updateEditingStep(e)}
                    />
                  );
                case "investor-type-business":
                  return (
                    <EntityContactInfoContent
                      isAccountEdit={true}
                      handleOptionClick={(e) => updateEditingStep(e)}
                    />
                  );
                case "investor-type-ria":
                  return (
                    <RIAContactInfoContent
                      isAccountEdit={true}
                      handleOptionClick={(e) => updateEditingStep(e)}
                    />
                  );
                default:
                  <></>;
              }
            })()}
          </div>
        </div>
      </div>
      <Dialog />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    editingStep: state[moduleTypes.INVESTOR_QUALIFICATION].editingStep,
    loading: state[moduleTypes.APP].loading
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateEditingStep: (value) => {
      dispatch({ type: actionTypes.EDIT_ACCOUNT_STEP, value})
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvestorQualification);
