import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { client as prismicClient } from "../../../libs/prismic";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import useSelectStyles from "../../../styles/components/Select";
import { isMobile } from "../../../utils";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { Link, navigate } from "gatsby";
import Loader from "../../Loader";
import { actionTypes } from "../../../store/InvestorQualification/type";

const AccountsEditLeft = ({ handlerStepChanged, currentStep, selectPageTypes, updateFormData }) => {
  const dispatch = useDispatch();
  const classes = useSelectStyles();
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  
  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle(
        'edit_account'
      );
      setDataDoc(response);
    }

    fetchData();
  }, []);

  const handlerSelectChange = (ev) => {
    handlerStepChanged(ev.target.value);
  };
 
  return dataDoc ? (
    <div className="individual-contact-info-container">
      <div className="individual-contact-logo-container mx-auto  text-center text-lg-start">
        <Link to="/invest-trade/">
          <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
        </Link>
      </div>
      <div className="info-container">
        <div className="previous-navigation text-center text-lg-start">
          <span onClick={() => {
              navigate('/accounts/')
          }}>
            &lt; Accounts
          </span>
        </div>
        <div className="question-no-container">
          <FormControl
            className={classes.styleSelectFormControl}
            variant="filled"
          >
            <InputLabel
              className={classes.styleSelect}
            >
              Edit investor info & profile
            </InputLabel>
            <Select
              onChange={handlerSelectChange}
              name="currentStep"
              className={classes.styleSelect}
              value={currentStep}
            >
              {selectPageTypes.map(({ name, code }) => (
                <MenuItem
                  key={code}
                  className={classes.styleSelectMenuItem}
                  value={code}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="info-heading-container  text-center text-lg-start">
          <p>{dataDoc.data?.title?.[0].text}</p>
        </div>
        <div
          className={clsx("info-desc-container", {
            oneline: seeMore && isMobile(),
          })}
        >
          <RichText render={dataDoc.data?.description} />
        </div>
        <div className="info-desc-container text-center text-lg-start">
          {isMobile() && (
            <>
              <span
                className="see-more-btn d-md-none"
                style={{ marginTop: "12px" }}
                onClick={() => setSeeMore(!seeMore)}
              >
                {seeMore ? "+ See more" : "- See less"} <br />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    updateFormData: (value) => {
      dispatch({ type: actionTypes.UPDATE_QUALIFICATION_ONE, value })
    },
  }
}

export default connect(undefined, mapDispatchToProps)(AccountsEditLeft);